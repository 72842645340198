import React, { useState, useEffect } from 'react';
import { Button, Upload, Progress, message } from 'antd';
import xlsx from 'xlsx';
import { UploadOutlined } from '@ant-design/icons';

const ImportExcel = ({ setExcelData, isDSRList, isDSRAdminList }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (files.length === 0) return;

    setLoading(true);
    setProgress(0);

    const reader = new FileReader();

    // Dinamik olarak dosya okuma ilerlemesini izliyoruz
    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        const percent = Math.round((event.loaded / event.total) * 100);
        setProgress(percent);
      }
    };

    reader.onload = async (e) => {
      try {
        const workbook = xlsx.read(e.target.result, { type: 'array' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const data = xlsx.utils.sheet_to_json(sheet);

        setExcelData(data); // Gelen veriyi üst bileşene ilet
        setProgress(100);
        message.success("Dosya başarıyla yüklendi!");
      } catch (error) {
        message.error("Bir hata oluştu. Lütfen dosyayı kontrol edin!");
      } finally {
        setLoading(false);
        setFiles([]); // İşlem bitiminde dosyaları temizle
      }
    };

    reader.readAsArrayBuffer(files[0]);
  }, [files, setExcelData]);

  return (
    <div style={{ textAlign: 'center' }}>
      <Upload
        beforeUpload={(file) => {
          setFiles([file]); // Tek dosya kabul ediliyor
          return false;
        }}
        showUploadList={false} // Yükleme sırasında liste gösterilmeyecek
      >
     <Button
          type="success"
          size="large"
          disabled={loading}
          loading={loading}
          icon={<UploadOutlined />}
        >
          {isDSRList && !isDSRAdminList
            ? "Import DSR"
            : isDSRAdminList
            ? "Import DSR Admin"
            : "Import Excel"}
        </Button>
      </Upload>

      {/* İlerleme Çubuğu */}
      {loading && (
        <div style={{ marginTop: 20, width: '50%', margin: '20px auto' }}>
          <Progress
            percent={progress}
            status={progress === 100 ? 'success' : 'active'}
          />
        </div>
      )}
    </div>
  );
};

export default ImportExcel;
